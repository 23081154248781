import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper/Wrapper";
const thanks = () => {
  return (
    <Layout>
      <Wrapper>
        <SEO />
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          お問い合わせありがとうございました。
        </div>
      </Wrapper>
    </Layout>
  );
};

export default thanks;
